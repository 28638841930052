import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactData } from '../formElements/redux/types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { formElementsActions, getContactData, getFiles } from '../formElements/redux/formElements.redux';
import { PrivacyPolicyCheckbox } from '../formElements/PrivacyPolicyCheckbox';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { RootState } from '../../../configuration/setup/store';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { TextInput } from '../formElements/TextInput';
import { extractQueryParameter } from '../../utils/queryParameters';
import { getIdToken, isLatamTenant } from '../../../configuration/tokenHandling/tokenSlice';
import { getLocale } from '../../../configuration/lang/langSlice';
import { v4 as uuidv4 } from 'uuid';
import FilePickerComponent from '../formElements/FileUpload';

interface Props {
    idToken?: any;
    locale: string;
    contactData: ContactData;
    setContactData: (data: ContactData) => void;
    setFiles: (files: File[]) => void;
    files: File[];
    isLatamTenant: boolean;
}

const vinParamName = 'vin';
const additionalInfoParamName = 'additional_info';

export const ContactMessageForm = ({
    idToken,
    contactData,
    setContactData,
    locale,
    files,
    setFiles,
    isLatamTenant,
}: Props) => {
    const messageAllowedMaxLength = 5000;
    const intl = useIntl();
    const privacyPolicyUrl = `https://rio.cloud/${locale.substring(0, 2)}/data-protection`;

    const toggleNewsletter = () => setContactData({ ...contactData, newsletter: !contactData.newsletter });
    const toggleAcceptSavingData = () =>
        setContactData({ ...contactData, acceptedSavingData: !contactData.acceptedSavingData });
    const setMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        if (inputValue.length === messageAllowedMaxLength) {
            Notification.error(intl.formatMessage({ id: 'contactForm.maximumLengthReached' }));
        }
        setContactData({ ...contactData, message: inputValue });
    };
    const setReason = (event: React.ChangeEvent<HTMLSelectElement>) =>
        setContactData({ ...contactData, reason: event.target.value });

    const queryVin = extractQueryParameter(vinParamName);
    const queryAdditionalInfo = extractQueryParameter(additionalInfoParamName);

    const setVin = (vin: string) => setContactData({ ...contactData, vin });

    useEffect(() => {
        setContactData({
            ...contactData,
            vin: queryVin ?? '',
            additionalInfo: queryAdditionalInfo,
            correlationId: uuidv4(),
        });
    }, []);

    return (
        <div>
            <div className="form-group col-xs-12 col-ls-6">
                <label className="control-label" htmlFor={'reason'}>
                    <FormattedMessage id={'contactForm.reason'} />*
                </label>
                <select
                    className="form-control"
                    onChange={setReason}
                    id={'reason'}
                    required={true}
                    value={contactData.reason}
                >
                    <option value="">{intl.formatMessage({ id: 'contactForm.pleaseSelect' })}</option>
                    <option value="other">{intl.formatMessage({ id: 'contactForm.reason.general' })}</option>
                    <option value="feedback">{intl.formatMessage({ id: 'contactForm.reason.feedback' })}</option>
                    <option value="partner-offering">
                        {intl.formatMessage({ id: 'contactForm.reason.partnerOfferings' })}
                    </option>
                    <option value="technical-problem">
                        {intl.formatMessage({ id: 'contactForm.reason.technical' })}
                    </option>
                </select>
            </div>
            <div className="col-xs-12 col-ls-6">
                <TextInput
                    id={'contactForm.vin'}
                    required={false}
                    setValue={setVin}
                    value={contactData.vin}
                    type={'text'}
                    maxLength={17}
                />
            </div>
            <div className="form-group col-xs-12 ">
                <label className="control-label" htmlFor={'contactForm.message'}>
                    <FormattedMessage id={'contactForm.message'} />
                    <span className="required">*</span>
                </label>
                <textarea
                    id={'contactForm.message'}
                    required={true}
                    className="form-control height-70"
                    rows={5}
                    value={contactData.message}
                    onChange={setMessage}
                    maxLength={messageAllowedMaxLength}
                />
            </div>
            {idToken && !isLatamTenant && (
                <>
                    <div className="form-group col-xs-12">
                        <label className="control-label" htmlFor={'contactForm.attachments'}>
                            <FormattedMessage id={'contactForm.attachments'} />
                        </label>
                        <FilePickerComponent files={files || []} setFiles={setFiles} />
                    </div>
                </>
            )}
            <div className="col-xs-12">
                <PrivacyPolicyCheckbox
                    privacyPolicyUrl={privacyPolicyUrl}
                    checkBoxLabel={'contactForm.rioPrivacyPolicy'}
                />
                {idToken && (
                    <Checkbox onClick={toggleAcceptSavingData}>
                        <FormattedMessage id={'contactForm.dataSharingAgreement'} />
                    </Checkbox>
                )}
                <Checkbox onClick={toggleNewsletter}>
                    <FormattedMessage
                        id="contactForm.confirmNewsletter"
                        values={{
                            link: (chunks) => <a href="mailto:marketing@rio.cloud">{chunks}</a>,
                        }}
                    />
                </Checkbox>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    idToken: getIdToken(state),
    locale: getLocale(state),
    contactData: getContactData(state),
    files: getFiles(state),
    isLatamTenant: isLatamTenant(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setContactData: (data: ContactData) => {
        const queryVin = extractQueryParameter(vinParamName);
        const additionalInfo = extractQueryParameter(additionalInfoParamName);

        const decodedAdditionalInfo = decodeURI(additionalInfo ?? '');

        dispatch(
            formElementsActions.setContactData({
                ...data,
                vin: data.vin ?? queryVin,
                additionalInfo: decodedAdditionalInfo,
            })
        );
    },
    setFiles: (files: File[]) => dispatch(formElementsActions.setFiles(files)),
});

export const ContactMessageFormContainer = connect(mapStateToProps, mapDispatchToProps)(ContactMessageForm);
